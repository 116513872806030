import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API';
import { AdvisingMeeting, MeetingStatus } from '../../Types/Advising';
import { htmlIf, maybeHtml } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';
import DatePicker from "react-datepicker";
import { getLocalTimeZoneName } from '../../Utils/DateTime';

type Props =
  { meeting: AdvisingMeeting
  , isAdmin: boolean

    /**
     * Called when a meeting is updated. Passes the updated meeting object to the parent.
     * @param updatedMeeting - The meeting object with updated details.
     */
    onUpdate: (updatedMeeting: AdvisingMeeting) => void;

    /**
     * Called when a meeting is rescheduled. Passes the old meeting ID and the new meeting object to the parent.
     * @param oldMeetingId - The ID of the meeting being replaced.
     * @param newMeeting - The new meeting object with updated scheduling details.
     */
    onReschedule: (oldMeetingId: number, newMeeting: AdvisingMeeting) => void;
  }

enum Modal
  { NoModal
  , MarkComplete
  , RescheduleMeeting
  }

const MeetingCard = (props: Props) => {
  const meeting = props.meeting;
  const pageLoadTime = new Date();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [modal, setModal] = useState(Modal.NoModal);
  const [sessionNotes, setSessionNotes] = useState('');
  const [rescheduleTime, setRescheduleTime] = useState<Date>(new Date(props.meeting.startAt));
  const [rescheduleMessage, setRescheduleMessage] = useState('');

  const isInFuture = (meeting: AdvisingMeeting): boolean => {
    const endTime = new Date(meeting.startAt).getTime() + meeting.scheduledDurationMinutes * 60 * 1000;
    return endTime > pageLoadTime.getTime();
  };

  function markComplete() {
    setIsSaving(true);

    const postBody = {
      meetingId: meeting.id,
      sessionNotes: sessionNotes
    }

    API.post("advisor_meetings_mark_meeting_complete_path", postBody).then(function (result) {
      const updatedMeeting = {
        ...meeting,
        status: MeetingStatus.COMPLETED,
        sessionNotes: sessionNotes,
      };

      setSessionNotes('');
      setModal(Modal.NoModal);
      props.onUpdate(updatedMeeting);
      setIsSaving(false);
    })
  }

  function rescheduleMeeting() {
    setIsSaving(true);

    const postBody = {
      meetingId: meeting.id,
      startTimeUtc: rescheduleTime.toISOString(),
      rescheduleMessage: rescheduleMessage
    }

    API.post("advisor_meetings_reschedule_path", postBody).then(function (result) {
      if (result['meeting']) {
        const newMeeting:AdvisingMeeting = result['meeting']
        props.onReschedule(meeting.id, newMeeting);
        setRescheduleTime(new Date(newMeeting.startAt))
      }

      setRescheduleMessage('');
      setModal(Modal.NoModal);
      setIsSaving(false);
    })
  }

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.MarkComplete:
        return (
          <DismissibleModal
            dialogClass='modal-lg'
            title={<h4>Mark session complete</h4>}
            body={
              <div>
                <div className="mb-0">Send a follow-up message to your client with notes about your meeting.</div>
                <textarea
                  className='form-control mt-1 t--session-notes'
                  value={sessionNotes}
                  onChange={(event) => setSessionNotes(event.target.value)}
                  rows={8}
                  placeholder='What did you discuss during your call? What follow-ups are required?'
                />
                <div className="mt-3 text-end">
                  <button className="btn btn-outline-danger px-6 me-2" onClick={() => setModal(Modal.NoModal)}>
                    Cancel
                  </button>
                  <button className="btn btn-primary px-2" onClick={markComplete}>
                    <i className="ai-circle-check me-1" />
                    Mark meeting complete
                  </button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.RescheduleMeeting:
        return (
          <DismissibleModal
            dialogClass='modal-lg'
            title={<h4>Reschedule Meeting</h4>}
            body={
              <div>
                Add a note with any additional details about your rescheduled meeting (optional).
                <textarea
                  className='form-control mt-2 t--reschedule-message'
                  value={rescheduleMessage}
                  onChange={(event) => setRescheduleMessage(event.target.value)}
                  rows={4}
                  placeholder='Why are you rescheduling the call? Are there any new action items?'
                />
                <div className="fs-md text-gray-900 mt-2 mb-1">Meeting Date/Time</div>
                <div className="w-100">
                  <DatePicker
                    wrapperClassName='w-100'
                    customInput={
                      <input className="form-control"/>
                    }
                    selected={rescheduleTime}
                    onChange={(date: Date) => setRescheduleTime(date)}
                    showTimeSelect
                    dateFormat="MM/dd/yy @ h:mm a"
                    minDate={new Date()} // Prevents dates before today
                    minTime={
                      rescheduleTime && rescheduleTime.toDateString() === new Date().toDateString()
                        ? new Date() // Restrict times before now if the selected date is today
                        : undefined // No time restriction for future dates
                    }
                    maxTime={
                      rescheduleTime && rescheduleTime.toDateString() === new Date().toDateString()
                        ? new Date(new Date().setHours(23, 59, 59)) // Restrict to the end of today if the selected date is today
                        : undefined // No time restriction for future dates
                    }
                    placeholderText='Click to select a date and time…'
                  />
                </div>
                <div className="fs-sm text-secondary">All times are shown in your local timezone ({getLocalTimeZoneName()})</div>
                <div className="mt-3 text-end">
                  <button className="btn btn-outline-danger px-6 me-2" onClick={() => setModal(Modal.NoModal)}>
                    Cancel
                  </button>
                  <button className="btn btn-primary px-2" onClick={rescheduleMeeting}>
                    <i className="ai-calendar-check me-1" />
                    Reschedule
                  </button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  return (
    <div key={meeting.id} className={`t--meeting-id-${meeting.id}`}>
      {ViewModal()}
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="">
            <div className="fw-semibold">
              {meeting.purchase.isConsultation ? 'Consultation' : 'Session'} with {meeting.purchase.customerName}
              {maybeHtml(meeting.purchase.sessionName, (sessionName) => (
                <span> ({sessionName})</span>
              ))}
              {maybeHtml(meeting.purchase.packageName, (packageName) => (
                <div className="fw-normal mb-1">
                  Package: 
                  <span className="fw-semibold text-success">{packageName}</span>
                </div>
              ))}
            </div>
            {new Date(meeting.startAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})} | {meeting.scheduledDurationMinutes} min
          </div>
          <i className={`fs-xl ${isExpanded ? 'ai-chevron-up' : 'ai-chevron-down'}`} />
        </div>
        {htmlIf(isExpanded,
          <div className="mt-3">
            {htmlIf(props.isAdmin,
              <div className="mb-2 bg-gray px-2 py-1 rounded-2">
                Advising Meeting ID: {meeting.id}  — 
                Advising Purchase ID: {meeting.purchase.id}
              </div>
            )}
            <div className="fw-semibold">Session Goals</div>
            <div className="">{meeting.sessionGoals || 'None provided'}</div>
            {maybeHtml(meeting.sessionNotes, (sessionNotes =>
              <>
                <div className="fw-semibold mt-3">Session Notes</div>
                <div className="">{sessionNotes}</div>
              </>
            ))}
            {htmlIf(meeting.status === MeetingStatus.ACTIVE,
              <div className="mt-3">
                {maybeHtml(meeting.zoomJoinUrl, (zoomUrl =>
                  <a className="btn btn-info bg-zoom-blue border-info me-2 px-2" href={zoomUrl} target={'_blank'}>
                    <i className="ai-video me-1" />
                    Join Zoom meeting
                  </a>
                ))}
                {htmlIf(!isInFuture(meeting),
                  <button className="btn btn-primary me-2 px-2" onClick={() => setModal(Modal.MarkComplete)}>
                    <i className="ai-circle-check me-1" />
                    Mark meeting complete
                  </button>
                )}
                <button className="btn btn-outline-secondary px-2" onClick={() => setModal(Modal.RescheduleMeeting)}>
                  <i className="ai-calendar me-1" />
                  Reschedule meeting
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
};

export default MeetingCard;
