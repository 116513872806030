import ReactOnRails from 'react-on-rails';

import AdvisorAccountProfile from '../src/Advisor/Account/Profile';
import AdvisorAccountEssays from '../src/Advisor/Account/Essays';
import AdvisorAccountAdvising from '../src/Advisor/Account/Advising';
import AdvisorAccountSchedulingCalendars from '../src/Advisor/Account/Scheduling/Calendars';
import AdvisorAccountSchedulingOfficeHours from '../src/Advisor/Account/Scheduling/OfficeHours';

import AdvisorEssayReviewGrid from '../src/Advisor/Essays/ReviewGrid';
import AdvisorEssayReviewDetails from '../src/Advisor/Essays/ReviewDetails';

import AdvisorMeetingsMeetingList from '../src/Advisor/Meetings/MeetingList';

import ConsultantProfile from '../src/Consultants/ConsultantProfile';
import ConsultantGrid from '../src/Consultants/ConsultantGrid';
import EssayReviewGrid from '../src/Consultants/EssayReviewGrid';

import AdvisingConsultCheckout from '../src/Advising/ConsultCheckout';
import AdvisingSessionCheckout from '../src/Advising/SessionCheckout';
import AdvisingPackagesCheckout from '../src/Advising/PackageCheckout';
import AdvisingPackagesSchedule from '../src/Advising/PackageSchedule';

import EssaysReviewDetails from '../src/Essays/ReviewDetails';
import EssaysReviewGrid from '../src/Essays/ReviewGrid';
import EssaysStandaloneReviewCards from '../src/Essays/StandaloneReviewCards';
import EssaysSubmit from '../src/Essays/Submit';

import MeetingsMeetingList from '../src/Meetings/MeetingList';

import Messages from '../src/Common/Messages';

// Any component referenced via a Rails call to `react_component` must be defined here, as well as in `server-bundle.js`.
ReactOnRails.register({
  AdvisorAccountProfile,
  AdvisorAccountEssays,
  AdvisorAccountAdvising,
  AdvisorAccountSchedulingCalendars,
  AdvisorAccountSchedulingOfficeHours,

  AdvisorEssayReviewGrid,
  AdvisorEssayReviewDetails,

  AdvisorMeetingsMeetingList,

  ConsultantProfile,
  ConsultantGrid,
  EssayReviewGrid,

  AdvisingConsultCheckout,
  AdvisingSessionCheckout,
  AdvisingPackagesCheckout,
  AdvisingPackagesSchedule,

  EssaysReviewDetails,
  EssaysReviewGrid,
  EssaysStandaloneReviewCards,
  EssaysSubmit,

  MeetingsMeetingList,

  Messages
});
