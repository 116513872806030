import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API';
import { AdvisingMeeting, MeetingStatus, packageScheduleSessionPath } from '../Types/Advising';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { DismissibleModal } from '../Components/Modal';
import DatePicker from "react-datepicker";
import { getLocalTimeZoneName } from '../Utils/DateTime';
import { advisorMessagesUrl, advisorPurchaseSessionPath } from '../Types/Advisor';

type Props =
  { meeting: AdvisingMeeting
  , isAdmin: boolean

    /**
     * Called when a meeting is updated. Passes the updated meeting object to the parent.
     * @param updatedMeeting - The meeting object with updated details.
     */
    onUpdate: (updatedMeeting: AdvisingMeeting) => void;

    /**
     * Called when a meeting is rescheduled. Passes the old meeting ID and the new meeting object to the parent.
     * @param oldMeetingId - The ID of the meeting being replaced.
     * @param newMeeting - The new meeting object with updated scheduling details.
     */
    onReschedule: (oldMeetingId: number, newMeeting: AdvisingMeeting) => void;
  }

enum Modal
  { NoModal
  , MarkComplete
  , RescheduleMeeting
  }

const MeetingCard = (props: Props) => {
  const meeting = props.meeting;
  const pageLoadTime = new Date();

  const [isExpanded, setIsExpanded] = useState(false);

  const isInFuture = (meeting: AdvisingMeeting): boolean => {
    const endTime = new Date(meeting.startAt).getTime() + meeting.scheduledDurationMinutes * 60 * 1000;
    return endTime > pageLoadTime.getTime();
  };

  const scheduleNextSessionPath = () => {
    if (props.meeting.purchase.packageName) {
      return packageScheduleSessionPath(meeting.purchase);
    } else {
      return advisorPurchaseSessionPath(meeting.purchase.advisorId);
    }
  }

  return (
    <div key={meeting.id} className={`t--meeting-id-${meeting.id}`}>
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <div className="">
            <div className="fw-semibold">
              {meeting.purchase.isConsultation ? 'Consultation' : 'Session'} with {meeting.purchase.advisorName}
              {maybeHtml(meeting.purchase.sessionName, (sessionName) => (
                <span> ({sessionName})</span>
              ))}
              {maybeHtml(meeting.purchase.packageName, (packageName) => (
                <div className="fw-normal mb-1">
                  Package: 
                  <span className="fw-semibold text-success">{packageName}</span>
                </div>
              ))}
            </div>
            {new Date(meeting.startAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short'})} | {meeting.scheduledDurationMinutes} min
          </div>
          <i className={`fs-xl ${isExpanded ? 'ai-chevron-up' : 'ai-chevron-down'}`} />
        </div>
        {htmlIf(isExpanded,
          <div className="mt-3">
            {htmlIf(props.isAdmin,
              <div className="mb-2 bg-gray px-2 py-1 rounded-2">
                Advising Meeting ID: {meeting.id}  — 
                Advising Purchase ID: {meeting.purchase.id}
              </div>
            )}
            <div className="fw-semibold">Session Goals</div>
            <div className="">{meeting.sessionGoals || 'None provided'}</div>
            {maybeHtml(meeting.sessionNotes, (sessionNotes =>
              <>
                <div className="fw-semibold mt-3">Session Notes</div>
                <div className="">{sessionNotes}</div>
              </>
            ))}
            <div className="mt-3">
              {htmlIf(meeting.status === MeetingStatus.ACTIVE,
                <>
                  {maybeHtml(meeting.zoomJoinUrl, (zoomUrl =>
                    <a className="btn btn-info bg-zoom-blue border-info px-2 me-2 t--join-zoom" href={zoomUrl} target={'_blank'}>
                      <i className="ai-video me-1" />
                      Join Zoom meeting
                    </a>
                  ))}
                  {/* <button className="btn btn-outline-secondary px-2 me-2 t--reschedule" onClick={() => setModal(Modal.RescheduleMeeting)}>
                    <i className="ai-refresh me-1" />
                    Reschedule
                  </button> */}
                </>
              )}
              <a className="btn btn-primary px-2 me-2 t--schedule-next-session" href={scheduleNextSessionPath()}>
                <i className="ai-calendar-plus me-1" />
                Schedule next session
              </a>
              <a className="btn btn-link px-0 t--messages" href={advisorMessagesUrl(meeting.purchase.advisorId)}>
                <i className="ai-messages me-1" />
                Messages
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default MeetingCard;
